import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

const initialState = {
  alerts: [],
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,

  reducers: {
    createAlert: (state, action) => {
      state.alerts.push({
        id: uuid(),
        message: action.payload.msg,
        type: action.payload.type,
      });
    },
    removeAlert: (state, action) => {
      return state.action.filter(id => id !== action.payload.id);
    },
  },
});

export const { createAlert, removeAlert } = alertSlice.actions;
export const alerts = state => state.alerts;
export default alertSlice.reducer;
