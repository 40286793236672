import { createSlice } from '@reduxjs/toolkit';
import Cookie from 'universal-cookie';

const cookie = new Cookie();
const initialState = {
  isLoading: false,
  isAuthenticated: false,
  error: '',
  userInfo: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,

  reducers: {
    userLoading: state => {
      state.isLoading = true;
    },

    userLoaded: (state, action) => {
      (state.isLoading = false),
        (state.isAuthenticated = true),
        (state.userInfo = action.payload);
    },

    userLoadFail: (state, action) => {
      (state.isLoading = false),
        (state.isAuthenticated = false),
        (state.error = action.payload);
    },
  },
});

export const { userLoadFail, userLoading, userLoaded } = userSlice.actions;
export const userInfo = state => state.user;
export default userSlice.reducer;
