import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  error: '',
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,

  reducers: {
    loginPending: state => {
      state.isLoading = true;
    },
    loginSuccess: state => {
      (state.isLoading = false),
        (state.isAuthenticated = true),
        (state.error = '');
    },
    loginFail: (state, action) => {
      (state.isLoading = false),
        (state.isAuthenticated = false),
        (state.error = action.payload);
    },
  },
});

export const { loginFail, loginPending, loginSuccess } = loginSlice.actions;
export const login = state => state.login;
export default loginSlice.reducer;
