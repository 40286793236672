import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  isRegistered: false,
  error: '',
  data: [],
};

export const signupSlice = createSlice({
  name: 'signup',
  initialState,

  reducers: {
    signupPending: state => {
      state.isLoading = true;
    },
    signupSuccess: (state, action) => {
      (state.isLoading = false),
        (state.isRegistered = true),
        (state.error = '');
      state.data = state.data.push(action.payload);
      toast.success(action.payload.message);
    },
    signupFail: (state, action) => {
      (state.isLoading = false),
        (state.isRegistered = false),
        (state.error = action.payload);
    },
  },
});

export const { signupFail, signupPending, signupSuccess } = signupSlice.actions;
export const signup = state => state.signup;
export default signupSlice.reducer;
