import "material-icons/iconfont/material-icons.css";
import "../styles/index.css";
import "../styles/globals.css";
import "../styles/dashboard-styles.css";
import "tippy.js/dist/tippy.css";
import "react-whatsapp-widget/dist/index.css";
import store from "../store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect } from "react";
import { CookiesProvider, useCookies } from "react-cookie";
import { useRouter } from "next/router";
import { QueryClient, QueryClientProvider, useQueryClient } from "react-query";

import Script from "next/script";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Head from "next/head";

// import Script from 'next/script'; // Ensure Script is imported

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const [cookie, setCookie] = useCookies();
  const queryClient = new QueryClient();
  const [notificationCount, setNotificationCount] = useState(0);
  // useEffect(() => {
  //   if ('serviceWorker' in navigator) {
  //     window.addEventListener('load', () => {
  //       navigator.serviceWorker.register('/service-worker.js')
  //         .then((registration) => {
  //           console.log('ServiceWorker registration successful with scope: ', registration.scope);
  //         }, (error) => {
  //           console.log('ServiceWorker registration failed: ', error);
  //         });
  //     });
  //   }


  // }, []);
  useEffect(() => {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js', { scope: '/dashboard/' });
      });
    }
  }, []);
  // useEffect(() => {
  //   const loadHeadway = () => {
  //     const script = document.createElement("script");
  //     script.src = "https://cdn.headwayapp.co/widget.js";
  //     script.async = true;
  //     script.onload = () => {
  //       if (window.Headway) {
  //         window.Headway.init({
  //           account: "7k9lvy",
  //           selector: ".CHANGE_THIS",
  //         });

  //         setTimeout(() => {
  //           updateNotificationCount();
  //           observeNotifications();
  //         }, 3000);
  //       }
  //     };
  //     document.body.appendChild(script);
  //   };

  //   const updateNotificationCount = () => {
  //     const logItems = document.querySelectorAll(".logItem");
  //     setNotificationCount(logItems.length);
  //   };

  //   const observeNotifications = () => {
  //     const targetNode = document.querySelector(".CHANGE_THIS");
  //     if (targetNode) {
  //       const observer = new MutationObserver(() => {
  //         updateNotificationCount();
  //       });
  //       const config = { childList: true, subtree: true };
  //       observer.observe(targetNode, config);
  //     }
  //   };

  //   if (!window.Headway) {
  //     loadHeadway();
  //   } else {
  //     window.Headway.init({
  //       account: "7k9lvy",
  //       selector: ".CHANGE_THIS",
  //     });

  //     setTimeout(() => {
  //       updateNotificationCount();
  //       observeNotifications();
  //     }, 3000);
  //   }
  // }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="apple-touch-icon" href="/logo-icon.svg" />
        <meta name="theme-color" content="#205363" />
        <meta name="description" content="A web application for managing affiliate programs, coupons, products, and more." />
        <title>Stakecut App</title>
      </Head>
      {/* Google Analytics Scripts */}
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-SFFYSR26VQ"
      />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-SFFYSR26VQ', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />

      <Script id="hotjar-init" strategy="afterInteractive">
        {`
          (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:5111945,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
      </Script>

      {cookie.authToken ? (
        <CookiesProvider>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <Component {...pageProps} />
              <ToastContainer autoClose={10000} />

              <Script
                id="show-freshdesk"
                onLoad={() => console.log("Hey Please Show Up")}
                type="text/javascript"
              >
                {`
                  var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
                  (function(){
                    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                    s1.async=true;
                    s1.src='https://embed.tawk.to/63db92a247425128791113b0/1go8r9j9b';
                    s1.charset='UTF-8';
                    s1.setAttribute('crossorigin','*');
                    s0.parentNode.insertBefore(s1,s0);
                  })();
                `}
              </Script>
            </QueryClientProvider>
          </Provider>
        </CookiesProvider>
      ) : (
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.reCAPTCHA}
          scriptProps={{
            async: false,
            defer: false,
            appendTo: "head",
            nonce: undefined,
          }}
          container={{
            parameters: {
              badge: "[bottomleft]",
              theme: "dark",
            },
          }}
        >
          <CookiesProvider>
            <Provider store={store}>
              <QueryClientProvider client={queryClient}>
                <Component {...pageProps} />
                <ToastContainer autoClose={10000} />

                <Script
                  id="show-freshdesk"
                  onLoad={() => console.log("Hey Please Show Up")}
                  type="text/javascript"
                >
                  {`
                    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
                    (function(){
                      var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                      s1.async=true;
                      s1.src='https://embed.tawk.to/63db92a247425128791113b0/1go8r9j9b';
                      s1.charset='UTF-8';
                      s1.setAttribute('crossorigin','*');
                      s0.parentNode.insertBefore(s1,s0);
                    })();
                  `}
                </Script>
              </QueryClientProvider>
            </Provider>
          </CookiesProvider>
        </GoogleReCaptchaProvider>
      )}
    </>
  );
}

export default MyApp;

// import 'material-icons/iconfont/material-icons.css';
// import '../styles/index.css';
// import '../styles/globals.css';
// import '../styles/dashboard-styles.css';
// import 'tippy.js/dist/tippy.css';
// import 'react-whatsapp-widget/dist/index.css';
// import store from '../store';
// import { Provider } from 'react-redux';
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import React, { useState, useEffect } from 'react';
// import { CookiesProvider, useCookies } from 'react-cookie';
// import { useRouter } from 'next/router';
// import { QueryClient, QueryClientProvider, useQueryClient } from 'react-query';

// import Script from 'next/script';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// function MyApp({ Component, pageProps }) {
//   const router = useRouter();
//   const [cookie, setCookie] = useCookies();

//   const queryClient = new QueryClient();
//   // useEffect(() => {
//   //   const script = document.createElement('script');
//   //   script.src = 'https://cdn.headwayapp.co/widget.js';
//   //   script.async = true;
//   //   script.onload = () => {
//   //     window.Headway && window.Headway.init({
//   //       account: 'J3DkMy',
//   //       selector: '.CHANGE_THIS',
//   //     });
//   //   };
//   //   document.body.appendChild(script);
//   // }, []);

// //   <script>
// //   // @see https://docs.headwayapp.co/widget for more configuration options.
// //   var HW_config = {
// //     selector: ".CHANGE_THIS", // CSS selector where to inject the badge
// //     account:  "J3DkMy"
// //   }
// // </script>
// // <script async src="https://cdn.headwayapp.co/widget.js"></script>

// // useEffect(() => {
// //   const loadHeadway = () => {
// //     const script = document.createElement('script');
// //     script.src = 'https://cdn.headwayapp.co/widget.js';
// //     script.async = true;
// //     script.onload = () => {
// //       window.Headway && window.Headway.init({
// //         account: 'J3DkMy',
// //         selector: '.CHANGE_THIS',
// //       });
// //     };
// //     document.body.appendChild(script);
// //   };

// //   if (!window.Headway) {
// //     loadHeadway();
// //   } else {
// //     window.Headway.init({
// //       account: 'J3DkMy',
// //       selector: '.CHANGE_THIS',
// //     });
// //   }
// // }, []);

// // useEffect(() => {
// //   const loadHeadway = () => {
// //     const script = document.createElement('script');
// //     script.src = 'https://cdn.headwayapp.co/widget.js';
// //     script.async = true;
// //     script.onload = () => {
// //       if (window.Headway) {
// //         window.Headway.init({
// //           account: 'J3DkMy',
// //           selector: '.CHANGE_THIS',
// //           onReady: () => {
// //             const notifications = document.querySelectorAll('.logItem');
// //             console.log('Number of notifications:', notifications.length);
// //           }
// //         });
// //       }
// //     };
// //     document.body.appendChild(script);
// //   };

// //   if (!window.Headway) {
// //     loadHeadway();
// //   } else {
// //     window.Headway.init({
// //       account: 'J3DkMy',
// //       selector: '.CHANGE_THIS',
// //       onReady: () => {
// //         const notifications = document.querySelectorAll('.logItem');
// //         console.log('Number of notifications:', notifications.length);
// //       }
// //     });
// //   }
// // }, []);
// // useEffect(() => {
// //   const loadHeadway = () => {
// //     const script = document.createElement('script');
// //     script.src = 'https://cdn.headwayapp.co/widget.js';
// //     script.async = true;
// //     script.onload = () => {
// //       if (window.Headway) {
// //         window.Headway.init({
// //           account: 'J3DkMy',
// //           selector: '.CHANGE_THIS',
// //         });

// //         // Check for notifications after a delay
// //         setTimeout(() => {
// //           const notifications = document.querySelectorAll('.logItem');
// //           console.log('Number of notifications:', notifications.length);
// //         }, 3000); // Adjust delay as needed
// //       }
// //     };
// //     document.body.appendChild(script);
// //   };

// //   if (!window.Headway) {
// //     loadHeadway();
// //   } else {
// //     window.Headway.init({
// //       account: 'J3DkMy',
// //       selector: '.CHANGE_THIS',
// //     });

// //     // Check for notifications after a delay
// //     setTimeout(() => {
// //       const notifications = document.querySelectorAll('.logItem');
// //       console.log('Number of notifications:', notifications.length);
// //     }, 3000); // Adjust delay as needed
// //   }
// // }, []);
// // useEffect(() => {
// //   const loadHeadway = () => {
// //     const script = document.createElement('script');
// //     script.src = 'https://cdn.headwayapp.co/widget.js';
// //     script.async = true;
// //     script.onload = () => {
// //       if (window.Headway) {
// //         window.Headway.init({
// //           account: 'J3DkMy',
// //           selector: '.CHANGE_THIS',
// //         });

// //         // Use setTimeout to allow time for the widget to load
// //         setTimeout(() => {
// //           // Check if notifications have already loaded
// //           const notifications = document.querySelectorAll('.logItem');
// //           console.log('Initial number of notifications:', notifications.length);

// //           // Use MutationObserver to watch for changes in the logList element
// //           const targetNode = document.querySelector('.CHANGE_THIS');
// //           if (targetNode) {
// //             const observer = new MutationObserver(() => {
// //               const notifications = document.querySelectorAll('.logItem');
// //               console.log('Updated number of notifications:', notifications.length);
// //             });

// //             const config = { childList: true, subtree: true };
// //             observer.observe(targetNode, config);
// //           }
// //         }, 3000); // Adjust delay as needed
// //       }
// //     };
// //     document.body.appendChild(script);
// //   };

// //   if (!window.Headway) {
// //     loadHeadway();
// //   } else {
// //     window.Headway.init({
// //       account: 'J3DkMy',
// //       selector: '.CHANGE_THIS',
// //     });

// //     // Use setTimeout to allow time for the widget to load
// //     setTimeout(() => {
// //       // Check if notifications have already loaded
// //       const notifications = document.querySelectorAll('.logItem');
// //       console.log('Initial number of notifications:', notifications.length);

// //       // Use MutationObserver to watch for changes in the logList element
// //       const targetNode = document.querySelector('.CHANGE_THIS');
// //       if (targetNode) {
// //         const observer = new MutationObserver(() => {
// //           const notifications = document.querySelectorAll('.logItem');
// //           console.log('Updated number of notifications:', notifications.length);
// //         });

// //         const config = { childList: true, subtree: true };
// //         observer.observe(targetNode, config);
// //       }
// //     }, 3000); // Adjust delay as needed
// //   }
// // }, []);
// const [notificationCount, setNotificationCount] = useState(0);
// useEffect(() => {
//   const loadHeadway = () => {
//     const script = document.createElement('script');
//     script.src = 'https://cdn.headwayapp.co/widget.js';
//     script.async = true;
//     script.onload = () => {
//       if (window.Headway) {
//         window.Headway.init({
//           account: '7k9lvy',
//           selector: '.CHANGE_THIS',
//         });

//         // Check initial notifications after a delay
//         setTimeout(() => {
//           updateNotificationCount();
//           observeNotifications();
//         }, 3000);
//       }
//     };
//     document.body.appendChild(script);
//   };

//   const updateNotificationCount = () => {
//     const logItems = document.querySelectorAll('.logItem');
//     setNotificationCount(logItems.length);
//     // console.log('Number of .logItem elements:', logItems.length);
//   };

//   const observeNotifications = () => {
//     const targetNode = document.querySelector('.CHANGE_THIS');
//     if (targetNode) {
//       const observer = new MutationObserver(() => {
//         updateNotificationCount();
//       });
//       const config = { childList: true, subtree: true };
//       observer.observe(targetNode, config);
//     //   console.log('MutationObserver set up on .CHANGE_THIS');
//     // } else {
//     //   console.log('Target node .CHANGE_THIS not found');
//     }
//   };

//   if (!window.Headway) {
//     loadHeadway();
//   } else {
//     window.Headway.init({
//       account: '7k9lvy',
//       selector: '.CHANGE_THIS',
//     });

//     // Check initial notifications after a delay
//     setTimeout(() => {
//       updateNotificationCount();
//       observeNotifications();
//     }, 3000);
//   }
// }, []);

//   // useEffect(() => {
//   //   const loadHeadway = () => {
//   //     const script = document.createElement('script');
//   //     script.src = 'https://cdn.headwayapp.co/widget.js';
//   //     script.async = true;
//   //     script.onload = () => {
//   //       if (window.Headway) {
//   //         window.Headway.init({
//   //           account: 'J3DkMy',
//   //           selector: '.CHANGE_THIS',
//   //         });

//   //         setTimeout(() => {
//   //           updateNotificationCount();

//   //           const targetNode = document.querySelector('.CHANGE_THIS');
//   //           if (targetNode) {
//   //             const observer = new MutationObserver(updateNotificationCount);
//   //             const config = { childList: true, subtree: true };
//   //             observer.observe(targetNode, config);
//   //           }
//   //         }, 3000); // Adjust delay as needed
//   //       }
//   //     };
//   //     document.body.appendChild(script);
//   //   };

//   //   const updateNotificationCount = () => {
//   //     const logItems = document.querySelectorAll('.logItem');
//   //     setNotificationCount(logItems.length);
//   //     console.log('Updated number of notifications:', logItems.length);
//   //   };

//   //   if (!window.Headway) {
//   //     loadHeadway();
//   //   } else {
//   //     window.Headway.init({
//   //       account: 'J3DkMy',
//   //       selector: '.CHANGE_THIS',
//   //     });

//   //     setTimeout(() => {
//   //       updateNotificationCount();

//   //       const targetNode = document.querySelector('.CHANGE_THIS');
//   //       if (targetNode) {
//   //         const observer = new MutationObserver(updateNotificationCount);
//   //         const config = { childList: true, subtree: true };
//   //         observer.observe(targetNode, config);
//   //       }
//   //     }, 3000); // Adjust delay as needed
//   //   }
//   // }, []);
// // useEffect(() => {
// //   const loadHeadway = () => {
// //     const script = document.createElement('script');
// //     script.src = 'https://cdn.headwayapp.co/widget.js';
// //     script.async = true;
// //     script.onload = () => {
// //       window.Headway && window.Headway.init({
// //         account: 'J3DkMy',
// //         selector: '.CHANGE_THIS',
// //       });
// //     };
// //     document.body.appendChild(script);
// //   };

// //   if (!window.Headway) {
// //     loadHeadway();
// //   } else {
// //     window.Headway.init({
// //       account: 'J3DkMy',
// //       selector: '.CHANGE_THIS',
// //     });
// //   }
// // }, []);
//   if (cookie.authToken) {
//     return (
//       <CookiesProvider>
//         <Provider store={store}>
//           <QueryClientProvider client={queryClient}>
//             <Component {...pageProps} />
//             <ToastContainer autoClose={10000} />

//             <Script
//               id="show-freshdesk"
//               onLoad={() => console.log('Hey Please Show Up')}
//               type="text/javascript"
//             >
//               {`
//                 var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
//                   (function(){
//                     var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
//                     s1.async=true;
//                     s1.src='https://embed.tawk.to/63db92a247425128791113b0/1go8r9j9b';
//                     s1.charset='UTF-8';
//                     s1.setAttribute('crossorigin','*');
//                     s0.parentNode.insertBefore(s1,s0);
//                   })();
//               `}
//             </Script>
//           </QueryClientProvider>
//         </Provider>
//       </CookiesProvider>
//     );
//   } else {
//     return (
//       <GoogleReCaptchaProvider
//         reCaptchaKey={process.env.reCAPTCHA}
//         scriptProps={{
//           async: false,
//           defer: false,
//           appendTo: 'head',
//           nonce: undefined,
//         }}
//         container={{
//           parameters: {
//             badge: '[bottomleft]', // optional, default undefined
//             theme: 'dark', // optional, default undefined
//           },
//         }}
//       >
//         <CookiesProvider>
//           <Provider store={store}>
//             <QueryClientProvider client={queryClient}>
//               <Component {...pageProps} />
//               <ToastContainer autoClose={10000} />

//               <Script
//                 id="show-freshdesk"
//                 onLoad={() => console.log('Hey Please Show Up')}
//                 type="text/javascript"
//               >
//                 {`
//                 var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
//                   (function(){
//                     var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
//                     s1.async=true;
//                     s1.src='https://embed.tawk.to/63db92a247425128791113b0/1go8r9j9b';
//                     s1.charset='UTF-8';
//                     s1.setAttribute('crossorigin','*');
//                     s0.parentNode.insertBefore(s1,s0);
//                   })();
//               `}
//               </Script>
//             </QueryClientProvider>
//           </Provider>
//         </CookiesProvider>
//       </GoogleReCaptchaProvider>
//     );
//   }
// }
// export default MyApp;
