import { createSlice } from '@reduxjs/toolkit';
import Cookie from 'universal-cookie';

const cookie = new Cookie();
const initialState = {
  updateProfileModal: false,
  updateProfileNoticeModal: false,
};

export const vendorSlice = createSlice({
  name: 'vendorActions',
  initialState,
  reducers: {
    openUpdateProfileModal: state => {
      state.updateProfileModal = true;
      state.updateProfileNoticeModal = false;
    },
    closeUpdateProfileModal: (state, action) => {
      state.updateProfileModal = false;
      state.updateProfileNoticeModal = false;
    },
    openUpdateProfileNoticeModal: (state, action) => {
      state.updateProfileModal = false;
      state.updateProfileNoticeModal = true;
    },
    closeUpdateProfileNoticeModal: (state, action) => {
      state.updateProfileModal = false;
      state.updateProfileNoticeModal = false;
    },
  },
});

export const {
  openUpdateProfileModal,
  closeUpdateProfileModal,
  openUpdateProfileNoticeModal,
  closeUpdateProfileNoticeModal,
} = vendorSlice.actions;
export const modal = state => state.vendorActions;
export default vendorSlice.reducer;
