// hello
import { configureStore } from '@reduxjs/toolkit';

import loginReducer from './auth/loginSlice';
import signupReducer from './auth/signupSlice';
import userReducer from './auth/userSlice';
import vendorReducer from './actions/vendorSlice';
import alertReducer from './alert';

const store = configureStore({
  reducer: {
    login: loginReducer,
    signup: signupReducer,
    user: userReducer,
    vandorActions: vendorReducer,
    alerts: alertReducer,
  },
});

export default store;
